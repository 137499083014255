import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, isChunkLoadError: false };
  }

  static getDerivedStateFromError(error) {
    // Check if the error is a chunk load error
    if (error.name === "ChunkLoadError") {
      return { hasError: true, isChunkLoadError: true };
    }
    // For other errors, just set hasError to true
    return { hasError: true, isChunkLoadError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service (e.g., Sentry, LogRocket)
    console.error("Error caught by ErrorBoundary:", error, errorInfo);

    // If it's a chunk load error, force a page reload
    if (error.name === "ChunkLoadError") {
      console.log("ChunkLoadError detected. Reloading page...");
      window.location.reload();
    }
  }

  handleReload = () => {
    // Reload the page to recover from the error
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "#F4F4F6", // Neutral light background
            color: "#752F87", // Theme primary color
            fontFamily: "Arial, sans-serif",
            padding: "20px",
          }}
        >
          <h1
            style={{
              fontSize: "2.5rem",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Oops! Something went wrong.
          </h1>
          <p
            style={{
              fontSize: "1rem",
              marginBottom: "30px",
              color: "#6D6E71", // Secondary text color
            }}
          >
            {this.state.isChunkLoadError
              ? "A new version of the app is available. Please refresh the page to load the latest version."
              : "We’re sorry for the inconvenience. Please try refreshing the page or contact support if the problem persists."}
          </p>
          <button
            onClick={this.handleReload}
            style={{
              padding: "10px 20px",
              fontSize: "1rem",
              fontWeight: "bold",
              color: "#FFFFFF",
              backgroundColor: "#752F87",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#BC54CB"; // Hover color
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#752F87"; // Original color
            }}
          >
            Refresh Page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;